import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getData, updateData } from '../utils/firebase';

interface Product {
  title: string;
  price: string;
  oldPrice?: string;
  description: string;
  img: string;
  id: string;
  link?: string;
}

export default function Product() {
  const [data, setData] = useState<Product>();
  const id = useParams().id?.split(':')[1];

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const uniqueId = Math.random().toString(36).slice(5);
    const date = new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
      })
      .replaceAll(',', '');
    e.preventDefault();
    updateData(`_MVP_store_/_events_/${id}/clicks/${date}`, { [uniqueId]: uniqueId });
    window.open(e.currentTarget.href, '_blank');
  };
  const impressionHandler = () => {
    const uniqueId = Math.random().toString(36).slice(5);
    const date = new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
      })
      .replaceAll(',', '');
    updateData(`_MVP_store_/_events_/${id}/impressions/${date}`, { [uniqueId]: uniqueId });
  };

  useEffect(() => {
    getData(`/_MVP_store_/_products_/${id}/_info_/`).then((data) => {
      data.description = data.description.replace(/\\n/g, '\n');
      setData(data);
    });
    impressionHandler();
  }, [id]);

  return (
    <section id="product">
      <Container
        className="justify-content-center align-items-center d-flex productContainer"
        style={{
          borderRadius: 25,
          background: '#081019',
          minHeight: '30rem',
          marginTop: '10%',
          marginBottom: '20%',
          display: 'flex',
          paddingBottom: '5%',
          paddingRight: '5%'
        }}>
        <Col className="mt-5 text-center">
          <img src={data?.img} style={{ width: '40vmax' }} />
        </Col>
        <Col style={{ paddingTop: '8%', display: 'grid' }}>
          <Row className="justify-content-end">
            <h4 className="text-end" style={{ paddingBottom: '15%' }}>
              {data?.title}
            </h4>
          </Row>
          <hr style={{ opacity: 0.1, marginLeft: '15%', marginBottom: '10%' }} />
          <Row className="justify-content-end">
            <p
              style={{
                color: 'rgba(255,255,255,0.6)',
                fontSize: 14,
                width: '80%',
                justifySelf: 'end',
                whiteSpace: 'pre-line',
                direction: 'rtl'
              }}>
              {data?.description}
            </p>
          </Row>
          <hr style={{ opacity: 0 }} />
          <Row className="justify-content-end">
            {data?.oldPrice ? (
              <h6
                className="text-end"
                style={{
                  color: 'rgb(255,255,255,0.6)',
                  textDecoration: 'line-through',
                  textDecorationColor: 'red'
                }}>
                {data?.oldPrice} ريال
                <br />
              </h6>
            ) : null}
          </Row>

          <Row className="justify-content-end">
            <h4 className="text-end" style={{ paddingBottom: '10%' }}>
              {data?.price} ريال
            </h4>
          </Row>
          <Row className="justify-content-end">
            <a
              className="btn link-light d-flex justify-content-center align-items-center"
              role="button"
              style={{
                borderRadius: 57,
                marginBottom: '10%',
                fontSize: 12,
                border: '2px solid #C70064',
                background: '#c80064',
                width: '80%',
                height: '65%',
                justifySelf: 'center'
              }}
              onClick={clickHandler}
              href={data?.link || '#about'}>
              شراء
            </a>
          </Row>
        </Col>
      </Container>
    </section>
  );
}

import { Card, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { updateData } from '../utils/firebase';
interface ProductProps {
  title: string;
  price?: string;
  oldPrice?: string;
  description?: string;
  image: string;
  id?: string;
  landing?: boolean;
}

export default function ProductCard({
  title,
  price,
  oldPrice,
  description,
  image,
  id,
  landing
}: ProductProps) {
  const Navigate = useNavigate();

  return (
    <>
      {landing ? (
        <Col md={6} className="justify-content-center d-flex">
          <div
            id="start-card"
            className="justify-content-center align-items-center"
            style={{
              boxShadow: '-16px 18px 4px 0px #C70064',
              width: '65%',
              borderRadius: 8,
              background: '#081019',
              minHeight: '30rem',
              marginTop: '15%',
              display: 'flex'
            }}>
            <div
              className="flex-column justify-content-center align-items-center"
              style={{ height: '100%', width: '100%', display: 'flex' }}>
              <h5 className="mt-4">المنتج الأكثر مبيعا</h5>
              <div
                className="d-flex text-center"
                style={{
                  width: '70%',
                  height: '80%',
                  borderRadius: 42,
                  marginBottom: '10%',
                  marginTop: '8%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#0d141e'
                }}>
                <img src={image} style={{ marginTop: '20%', height: 180 }} />
                <p
                  style={{
                    color: 'rgb(255,255,255)',
                    marginTop: '15%',
                    fontSize: 14,
                    marginBottom: '15%',
                    paddingRight: '20%',
                    paddingLeft: '20%'
                  }}>
                  {title} <br />
                </p>
              </div>
              <button
                className="btn link-light d-flex justify-content-center align-items-center"
                type="button"
                style={{
                  borderRadius: 57,
                  marginBottom: '10%',
                  fontSize: 12,
                  border: '2px solid #C70064',
                  background: '#c80064',
                  width: '50%',
                  height: '10%'
                }}
                onClick={() => Navigate(`/product/:${id}`)}>
                شراء
              </button>
              <div
                style={{
                  position: 'absolute',
                  width: 90,
                  boxShadow: '4px 30px 200px 80px #C70064',
                  zIndex: -1,
                  transform: 'translate(80%)'
                }}></div>
            </div>
          </div>
          <div id="hiddendiv" style={{ display: 'none' }}></div>
        </Col>
      ) : (
        <Col className="d-flex prod">
          <Card
            className="border-0 flex-fill prodCard"
            onClick={() => {
              Navigate(`/product/:${id}`);
            }}>
            <Card.Img
              variant="top"
              className="w-100 d-block fit-cover"
              src={image}
              style={{ height: 200, backgroundColor: '#0b121b' }}
            />
            <Card.Body
              className="d-flex flex-column justify-content-between align-items-center p-4 text-center"
              style={{
                background: '#0b121b',
                borderBottom: '4px solid #C70064'
              }}>
              <Card.Title
                style={{
                  color: 'rgb(255,255,255)',
                  marginTop: '15%',
                  fontSize: 14,
                  marginBottom: '15%'
                }}>
                {title}
                <br />
              </Card.Title>
              <p style={{ color: 'rgb(255,255,255,0.6)', fontSize: 14 }}>
                {oldPrice ? (
                  <p
                    style={{
                      color: 'rgb(255,255,255,0.6)',
                      fontSize: 12,
                      textDecoration: 'line-through',
                      textDecorationColor: 'red',
                      marginBottom: '0'
                    }}>
                    {oldPrice} ريال
                    <br />
                  </p>
                ) : null}
                {price} ريال
                <br />
              </p>
              <button
                className="btn link-light d-flex justify-content-center align-items-center"
                type="button"
                style={{
                  borderRadius: 57,
                  marginBottom: '10%',
                  fontSize: 12,
                  background: '#c80064',
                  height: '2rem',
                  width: '7rem'
                }}>
                شراء
              </button>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
}

import { Route, Routes } from 'react-router-dom';
import Landing from './models/Landing';
import Layout from './models/Layout';
import Product from './models/Product';
import './App.css';

export default function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/product">
          <Route path=":id" element={<Product />} />
        </Route>
      </Routes>
    </Layout>
  );
}

import { initializeApp } from 'firebase/app';
import { get, getDatabase, ref, set, update } from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDJOTudWDfsOIYBnlaII4c-KsmV4UfuhEA',
  authDomain: 'hoplay-77ef1.firebaseapp.com',
  databaseURL: 'https://hoplay-77ef1.firebaseio.com',
  projectId: 'hoplay-77ef1',
  storageBucket: 'hoplay-77ef1.appspot.com',
  messagingSenderId: '534788815068',
  appId: '1:534788815068:web:b134f5269e050d6f055c19'
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export const getData = (path: string) =>
  get(ref(database, path)).then((snapshot) => snapshot.val());

export const setData = (path: string, data: object) => set(ref(database, path), data);

export const updateData = (path: string, data: object) => update(ref(database, path), data);

import { FormEvent, useEffect, useState } from 'react';
import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap';
import { getData, updateData } from '../utils/firebase';
import ProductCard from './ProductCard';

interface DataStructure {
  _products_: {
    [key: string]: {
      _info_: {
        title: string;
        price: number;
        oldPrice?: number;
        description: string;
        id: number;
        img: string;
      };
    };
  };

  _promo_product_: {
    [key: string]: {
      _info_: {
        title: string;
        price: number;
        oldPrice?: number;
        description: string;
        id: number;
        img: string;
      };
    };
  };

  _events_: {
    impressions: {
      [key: string]: any;
    };
    clicks: {
      [key: string]: any;
    };
  }[];
}

export default function Landing() {
  const [products, setProducts] = useState<DataStructure['_products_'][string][]>();
  const [promo, setPromo] = useState<DataStructure['_promo_product_'][string]['_info_']>();
  const [showToast, setShowToast] = useState(false);
  const getValue = (id: string) => {
    return (document.getElementById(id) as HTMLInputElement | HTMLTextAreaElement).value;
  };

  const contactHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const uniqueId = Math.random().toString(36).slice(5);
    const date = new Date()
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short'
      })
      .replaceAll(',', '');
    updateData(`_MVP_store_/_contactus_/${date}`, {
      [uniqueId]: {
        id: uniqueId,
        name: getValue('name-input'),
        phone: getValue('phone-input'),
        msg: getValue('message-input'),
        time: Date.now()
      }
    }).then(() => setShowToast(true));
  };

  useEffect(() => {
    getData('/_MVP_store_/').then((data: DataStructure) => {
      const prodArr: DataStructure['_products_'][any][] = [];
      for (const [key, value] of Object.entries(data._products_)) {
        prodArr.push(value);
      }
      setProducts(prodArr);

      for (const [key, value] of Object.entries(data._promo_product_)) {
        setPromo(value._info_);
      }
    });
  }, []);
  return (
    <>
      <section id="home">
        <Container>
          <Row>
            <ProductCard
              title={promo?.title || 'بلايستيشن 5 جديد مع كنترولر, ولعبتين فقط بي 1,200 ريال'}
              price={promo?.price?.toString() || '120'}
              oldPrice={promo?.oldPrice?.toString() || undefined}
              image={
                promo?.img ||
                'https://firebasestorage.googleapis.com/v0/b/hoplay-77ef1.appspot.com/o/_MVP_store_%2Fps5.png?alt=media&token=9189f9b6-c397-4abf-b263-5b3003b6de35'
              }
              id={promo?.id?.toString() || '0'}
              landing
            />
            <Col
              md={6}
              className="d-flex"
              style={{ alignItems: 'center', justifyContent: 'center' }}>
              <div
                className="d-flex text-center"
                style={{ alignItems: 'center', justifyItems: 'center', flexDirection: 'column' }}>
                <p id="home-heading" style={{ color: '#fff', fontSize: 36 }}>
                  <span style={{ color: '#C70064' }}>بدون دراما وبدون وجع راس </span>
                  انتقينا لكم افضل المنتجات في متجر هوبلاي
                </p>
                <div id="hiddenblur" />
                <a
                  className="btn link-light"
                  role="button"
                  style={{
                    borderRadius: 57,
                    paddingRight: 35,
                    paddingLeft: 35,
                    paddingTop: 12,
                    paddingBottom: 12,
                    marginTop: '10%',
                    fontSize: 12,
                    position: 'relative',
                    zIndex: 1,
                    border: '2px solid #ffffff',
                    transition: 'all 0.3s ease-in-out'
                  }}
                  href="#products">
                  استعرض المنتجات
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ marginTop: '25%' }}>
        <Container>
          <Row
            className="gx-5 gy-5 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-4 row-cols-xs-2"
            id="products">
            {products?.map((product) => {
              return (
                <ProductCard
                  key={product._info_.id}
                  title={product._info_.title}
                  description={product._info_.description}
                  oldPrice={product?._info_.oldPrice?.toString() || undefined}
                  price={product._info_.price.toString()}
                  image={product._info_.img}
                  id={product._info_.id.toString()}
                />
              );
            })}
          </Row>
        </Container>
      </section>

      <section id="contactus" style={{ marginTop: '20%' }}>
        <Container>
          <ToastContainer position="top-end" className="mt-5 p-3" containerPosition="fixed">
            <Toast onClose={() => setShowToast(false)} show={showToast} autohide bg="dark">
              <Toast.Body>.رسالتك وصلت وراح نتواصل معك باقرب وقت</Toast.Body>
            </Toast>
          </ToastContainer>
          <Row>
            <Col lg={5} className="d-flex flex-column justify-content-center align-items-end">
              <div className="mb-3">
                <h6 className="text-end" style={{ color: 'rgba(255,255,255,0.6)', fontSize: 18 }}>
                  ارسل لنا
                </h6>
                <p style={{ color: 'rgb(255,255,255)', fontSize: 20 }}>store@hoplay.online</p>
              </div>
              <div className="mb-3">
                <h6 className="text-end" style={{ color: 'rgba(255,255,255,0.6)', fontSize: 18 }}>
                  اتصل بنا
                </h6>
                <p style={{ color: 'rgb(255,255,255)', fontSize: 20 }}>+966 569194816</p>
              </div>
              <div className="mb-3">
                <h6 className="text-end" style={{ color: 'rgba(255,255,255,0.6)', fontSize: 18 }}>
                  زورنا
                </h6>
                <p style={{ color: 'rgb(255,255,255)', fontSize: 20 }}>
                  Al Sahaba Rd, Al Yarmuk Riyadh 13243
                </p>
              </div>
              <div className="mb-3">
                <h6 style={{ color: 'rgba(255,255,255,0.6)', fontSize: 18 }}>تابعنا</h6>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ height: '4rem', width: '18rem', marginBottom: '15%' }}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => window.open('https://twitter.com/HoplayOnline')}
                  style={{
                    background: '#C70064',
                    width: '3rem',
                    marginRight: '2%',
                    marginLeft: '2%',
                    height: '3rem',
                    borderRadius: 5,
                    cursor: 'pointer'
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="60%">
                    <path
                      fill="white"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => window.open('https://linkedin.com/company/hoplayonline')}
                  style={{
                    background: '#C70064',
                    width: '3rem',
                    marginRight: '2%',
                    marginLeft: '2%',
                    height: '3rem',
                    borderRadius: 5,
                    cursor: 'pointer'
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="60%">
                    <path
                      fill="white"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => window.open('https://www.instagram.com/hoplay.online/')}
                  style={{
                    background: '#C70064',
                    width: '3rem',
                    marginRight: '2%',
                    marginLeft: '2%',
                    height: '3rem',
                    borderRadius: 5,
                    cursor: 'pointer'
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" height="60%">
                    <path
                      fill="white"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    />
                  </svg>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center align-content-center"
                  onClick={() => window.open('https://www.instagram.com/HoplayOnline/')}
                  style={{
                    background: '#C70064',
                    width: '3rem',
                    marginRight: '2%',
                    marginLeft: '2%',
                    height: '3rem',
                    borderRadius: 5,
                    cursor: 'pointer'
                  }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="70%">
                    <path
                      fill="white"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                </div>
              </div>
            </Col>
            <Col md={6} className="offset-lg-1">
              <div style={{ marginTop: '15%', paddingLeft: '5%', paddingRight: '5%' }}>
                <form onSubmit={contactHandler}>
                  <h1
                    style={{
                      color: 'var(--bs-white)',
                      fontSize: 32,
                      marginBottom: '10%',
                      textAlign: 'right'
                    }}>
                    <strong>تواصل معنا</strong>
                  </h1>
                  <div className="mb-3">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      id="name-input"
                      style={{
                        background: '#0a121b',
                        marginBottom: '10%',
                        borderStyle: 'none',
                        borderRadius: 38,
                        textAlign: 'right'
                      }}
                      placeholder="...الإسم"
                      name="name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      id="phone-input"
                      style={{
                        background: '#0a121b',
                        marginBottom: '10%',
                        borderStyle: 'none',
                        borderRadius: 38,
                        textAlign: 'right'
                      }}
                      placeholder="...رقم الجوال / الإيميل"
                      name="name"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control form-control-lg"
                      id="message-input"
                      style={{
                        background: '#0a121b',
                        borderStyle: 'none',
                        borderRadius: 5,
                        marginBottom: '10%',
                        resize: 'none',
                        textAlign: 'right'
                      }}
                      name="message"
                      placeholder="...الرسالة"
                      rows={5}
                      autoComplete="off"
                      minLength={5}
                      required
                    />
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ height: '4rem', marginBottom: '15%' }}>
                    <button
                      className="btn"
                      id="submit-btn"
                      type="submit"
                      style={{
                        background: '#C70064',
                        color: 'var(--bs-white)',
                        fontSize: 16,
                        fontWeight: 900,
                        width: '40%',
                        borderRadius: 57,
                        height: '80%'
                      }}>
                      <strong>إرسال</strong>
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

import { ReactNode, useEffect, useState } from 'react';
import { Collapse, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
type Props = {
  children: ReactNode | ReactNode[];
};
export default function Layout({ children }: Props) {
  const [open, setOpen] = useState(false);
  const Navigate = useNavigate();

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    window.onscroll = () => {
      if (window.scrollY > 300) {
        navbar?.classList.add('nav-active');
      } else {
        navbar?.classList.remove('nav-active');
      }
    };
  }, []);
  return (
    <>
      <nav
        className="navbar navbar-expand-sm sticky-top navigation-clean"
        style={{ background: 'transparent' }}>
        <Container className="align-items-center d-flex">
          <button className="navbar-brand my-auto me-auto" onClick={() => Navigate('/')}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/hoplay-77ef1.appspot.com/o/_MVP_store_%2Fhoplay.png?alt=media&token=c2b1c3ed-89ac-44f2-a621-daa5da25094a"
              height="70"
            />
          </button>
          <button
            data-bs-toggle="collapse"
            data-bs-target="meow"
            className="navbar-toggler ms-auto"
            onClick={() => {
              !open ? setOpen(true) : setOpen(false);
            }}
            style={{ borderStyle: 'none' }}>
            <span>
              <svg
                width="22px"
                height="26px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path
                  fill="white"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                />
              </svg>
            </span>
          </button>
          <Collapse in={open}>
            <div className="navbar-collapse" id="meow">
              <ul className="navbar-nav d-flex ms-auto">
                <li className="nav-item d-flex justify-content-end mx-4">
                  <a
                    className="nav-link"
                    href="https://appurl.io/vAH8r6wNt"
                    style={{ color: '#ffffff', opacity: 0.6 }}
                    target="_blank"
                    rel="noreferrer">
                    حمل التطبيق
                  </a>
                </li>
                <li className="nav-item d-flex justify-content-end mx-4">
                  <a
                    className="nav-link"
                    href="https:\\hoplay.online"
                    style={{ color: '#ffffff', opacity: 0.6 }}
                    target="_blank"
                    rel="noreferrer">
                    هوبلاي
                  </a>
                </li>
                <li
                  className="nav-item d-flex justify-content-end mx-4"
                  style={{ color: '#ffffff' }}>
                  <a
                    className="nav-link"
                    href="#products"
                    onClick={() => Navigate('/#products')}
                    style={{ color: '#ffffff', opacity: 0.6 }}>
                    المنتجات
                  </a>
                </li>
                <li
                  className="nav-item d-flex justify-content-end mx-4"
                  style={{ color: '#ffffff' }}>
                  <a className="nav-link" href="#" onClick={() => Navigate('/')}>
                    <span
                      style={{
                        borderRadius: 1,
                        color: 'rgb(255,255,255)',
                        borderColor: '#c70064',
                        borderBottomWidth: 2,
                        paddingBottom: '20%',
                        borderBottomStyle: 'solid'
                      }}>
                      الرئيسية
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </Collapse>
        </Container>
      </nav>

      {children}

      <footer style={{ marginBottom: '2%' }}>
        <div style={{ position: 'fixed', bottom: 50, right: 50, cursor: 'pointer', zIndex: 2 }}>
          <div style={{ width: '6rem', height: '2rem' }}>
            <a id="contactusFloat" href="#contactus" onClick={() => Navigate('/')}>
              تواصل معنا
            </a>
          </div>
        </div>
        <div className="row mx-3">
          <div className="col d-flex flex-row justify-content-start align-items-center">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/hoplay-77ef1.appspot.com/o/_MVP_store_%2Flgnds.png?alt=media&token=599632dd-ad7b-4dcf-a803-4a6ae50bf27f"
              style={{ width: 70, marginRight: '1rem' }}
            />
            <p
              style={{
                color: 'rgba(255,255,255,0.4)',
                marginTop: '3%',
                fontSize: 16,
                textAlign: 'left',
                alignSelf: 'end'
              }}>
              Powered by Legends Gaming
            </p>
            <div
              style={{
                position: 'absolute',
                width: 70,
                boxShadow: '4px 22px 200px 40px #C70064',
                zIndex: -1,
                left: 0
              }}></div>
          </div>
        </div>
      </footer>
    </>
  );
}
